.Image__container {
  break-inside: avoid;
}

div.img-skeleton {
  /*width: 366px;*/
  /*height: 207px;*/
  animation: skeleton-animation 1s linear infinite alternate;
  opacity: .7;
}

@keyframes skeleton-animation {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@media screen and (max-width: 768px) {
  div.img-skeleton {
    /*width: 20%;*/
    width: 100%;
    /*height: 207px;*/
    animation: skeleton-animation 1s linear infinite alternate;
    opacity: .7;
  }
}