.MenuItem {
  padding: 15px;
}

.MenuItem__link {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.MenuItem__link:hover {
  cursor: pointer;
  color: #ff0000;
  text-shadow: 0px 0px 10px #ff0000;
}
