.GalleryList__container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    margin: auto;
}

.GalleryList__container > div {
    flex-basis: 22%;
}

@media screen and (max-width: 768px) {
    .GalleryList__container > div {
        flex-basis: 45%;
    }
}

@media screen and (max-width: 576px) {
    .GalleryList__container {
        flex-direction: column;
        padding: 100px 25px 50px 25px;
    }
}
