.Gallery__container {
  padding: 0;
  margin: 0;
}

.Gallery__container h1 {
  padding-top: 80px;
  padding-left: 10px;
}

.Gallery__images__wrapper {
  columns: 5;
  column-gap: 5px;
  min-height: 100vh;
}

.Gallery--galleryPage {
  min-height: 95vh;
  width: 100%;
}

.Gallery--galleryPage .Gallery__images__wrapper {
  padding: 0 25px 0 25px;
}

.Gallery__vr-images__wrapper {
  display: flex;
  column-width: 25px;
  gap: 10px;
  padding: 0 25px 50px 25px;
  /*flex-wrap: wrap;*/
  justify-content: center;
}

.Gallery__vr-images__wrapper > div {
  flex-basis: 32%;
}

@media (max-width: 576px) {
  .Gallery__images__wrapper {
    columns: 1;
  }

  .Gallery__vr-images__wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .Gallery__images__wrapper {
    columns: 3;
  }

  .Gallery__vr-images__wrapper > div {
    flex-basis: 45%;
  }

}
