.Footer__container {
    position: relative;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.5) 50%,
            rgba(0, 0, 0, 0.1) 95%,
            rgba(0, 0, 0, 0) 100%
    );
    height: 50px;
}

@media screen and (max-width: 577px) {
    .Footer__container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 75px;
    }
}
