header,
.Home__section {
  position: relative;
  min-height: 100vh;
}

.Home__header__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.Home__header__anim__container {
  display: flex;
  height: 175px;
  overflow: hidden;
}

.Home__header__logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  width: 40vw;
  height: 100%;
  overflow: hidden;
}

.Home__header__logo h1 {
  animation: logoAnimation 1s 1s both;
}

.Home__header__spacer {
  width: 0;
  height: 100%;
  border: 2px solid #fff;
  animation: spacerAnimation 1s both;
}

.Home__header__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  width: 40vw;
  height: 100%;
  overflow: hidden;
}

.Home__header__info div {
  animation: infoAnimation 1s 2s both;
}

@keyframes logoAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
    letter-spacing: 10px;
  }

  50% {
    letter-spacing: 5px;
  }
}

@keyframes infoAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    letter-spacing: 10px;
  }

  50% {
    letter-spacing: 5px;
  }
}

@keyframes spacerAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@media (min-width: 2000px) {
  .Home__header__container {
    font-size: 2rem;
  }
  .Home__header__logo h1 {
    font-size: 5rem;
  }
  .Home__header__anim__container {
    height: 275px;
  }
}

@media (max-width: 576px) {
  .Home__header__anim__container {
    flex-direction: column;
    width: 80%;
  }

  .Home__header__spacer {
    width: 100%;
    height: 0;
    border: 2px solid #fff;
    border-radius: 5px;
    animation: spacerAnimation 1s both;
  }

  .Home__header__logo {
    text-align: center;
    justify-content: center;
  }

  .Home__header__logo,
  .Home__header__info {
    width: 100%;
    padding: 0;
    justify-content: center;
  }

  .Home__header__info {
    text-align: center;
  }

  @keyframes logoAnimation {
    0% {
      opacity: 0;
      transform: translateY(100%);
      letter-spacing: 10px;
    }

    50% {
      letter-spacing: 5px;
    }
  }

  @keyframes infoAnimation {
    0% {
      opacity: 0;
      transform: translateY(-100%);
      letter-spacing: 10px;
    }

    50% {
      letter-spacing: 5px;
    }
  }

  @keyframes spacerAnimation {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
}

.Home__about__list {
  list-style-type: none;
}

.Home__about {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.Home__about__content {
  width: 75%;
  border: 2px solid #fff;
  border-radius: 15px;
  backdrop-filter: brightness(40%) blur(5px);
  padding: 75px 50px;
}

.Home__contact__container {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .Home__about__content {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

@media screen and (max-width: 768px) {
  .Home__about__content {
    width: 90%;
    padding: 40px 20px;
  }
}
