.FullscreenGallery__container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}

.FullscreenGallery__img__container {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FullscreenGallery__img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}

.FullscreenGallery__button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  outline: none;
  background: transparent;
  color: #ffff;
  font-size: 35px;
}

@media screen and (max-width: 768px) {
  .FullscreenGallery__container {
    padding: 0 5px;
  }

  .FullscreenGallery__img__container {
    width: 95%;
    height: 95%;
  }
}
