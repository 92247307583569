.Menu {
  width: 100%;
  height: 60px;
  z-index: 9997;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  transition: background 0.25s ease-in-out;
  /* background: rgba(0, 0, 0, 0.9); */
  /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75); */
}

.mobileMenu {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 1);
  position: absolute;
  z-index: 9998;
  transform: translateX(100%);
  transition: transform 0.15s ease-out;
}

.mobileMenu-list {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobileMenu--active {
  transform: translateX(0);
  transition: transform 0.15s ease-out;
}

.mobile-menu-button {
  display: block;
  position: relative;
  z-index: 9999;
  background: none;
  outline: none;
  border: none;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  margin-right: 5px;
}

.Menu--sticky {
  transition: background 0.25s ease-in-out;
  background: rgba(0, 0, 0, 0.95);
}

.menu-list {
  width: 50%;
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  /*background: linear-gradient(rgba(0, 0, 0, 0), 95%, rgba(255, 0, 0, 0.5) 100%);*/
  box-shadow: 50px 2px 40px 2px #ff0000;
}

.logo {
  margin: 8px;
  height: 50px;
}

.hamburger-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  right: 12px;
}

.hamburger {
  position: relative;
  width: 20px;
  height: 2px;
  background: #fff;
  transition: background 0.5s ease-in;
  transition-delay: 0.4s;
}

.hamburger::before,
.hamburger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 20px;
  height: 2px;
  background: #fff;
}

.hamburger::after {
  top: -6px;
  transform: rotate(0) translateY(0);
  transition: transform 0.2s ease-in-out;
  transition-delay: 0.3s;
}

.hamburger::before {
  top: 6px;
  transform: rotate(0) translateY(0);
  transition: transform 0.2s ease-in-out;
  transition-delay: 0.3s;
}

.hamburger--active .hamburger {
  background: transparent;
  transition: background 0.15s ease-in;
}

.hamburger--active .hamburger::before {
  transform: translateY(-6px) rotate(-45deg);
  transition: transform 0.2s ease-in-out;
  transition-delay: 0.15s;
}

.hamburger--active .hamburger::after {
  transform: translateY(6px) rotate(45deg);
  transition: transform 0.2s ease-in-out;
  transition-delay: 0.15s;
}

@media (max-width: 768px) {
  .menu-list {
    width: 75%;
    display: none;
  }
}
