.Contact__map {
    height: 70%;
    max-height: 900px;
    min-height: 600px;
    border-radius: 25%;
    border: 5px solid rgba(0,0,0, 0.75);
    transform: rotate(45deg);
    background: rgba(0,0,0, .75);
    aspect-ratio: 1 / 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Contact__map img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    transform: rotate(-45deg);
}

.Contact__info {
    font-size: 1.2rem;
}

.Contact__container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .Contact__container {
        flex-direction: column;
        min-height: 100%;
    }

    .Contact__map {
        transform: rotate(0deg);
    }

    .Contact__map img {
        transform: rotate(0deg);
    }

    .Contact__info {
        text-align: center;
    }
}