.Background__bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-image: -webkit-repeating-linear-gradient(
      45deg,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0) 1.6%,
      hsla(0, 0%, 0%, 0.1) 1.75%,
      hsla(0, 0%, 0%, 0) 1.9%
    ),
    -webkit-repeating-linear-gradient(45deg, hsla(0, 0%, 10%, 0) 0%, hsla(
            0,
            0%,
            10%,
            0
          )
          1.4%, hsla(0, 0%, 10%, 0.03) 1.45%, hsla(0, 0%, 10%, 0) 1.6%),
    -webkit-repeating-linear-gradient(45deg, hsla(0, 0%, 0%, 0) 0%, hsla(
            0,
            0%,
            0%,
            0
          )
          1.2%, hsla(0, 0%, 0%, 0.15) 1.22%, hsla(0, 0%, 0%, 0) 1.4%),
    -webkit-linear-gradient(45deg, hsl(0, 0%, 22%) 0%, hsl(0, 0%, 10%) 47%, hsl(
            0,
            0%,
            22%
          )
          53%, hsl(0, 0%, 30%) 100%);
}

.Background__backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  backdrop-filter: brightness(40%) blur(2px);
  -webkit-backdrop-filter: brightness(40%) blur(2px);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .Background__backdrop {
    background-color: rgba(0, 0, 0, 0.65);
  }
}
